<template>
  <v-container class="fill-heigt" fluid>
    <v-row class="justify-center" style="margin-top: -45px">
      <v-col cols="12" md="12">
        <v-alert
          v-model="alert"
          dense
          type="info"
          class="text-center caption mb-0"
          dismissible
          >{{ $t("alert_approved_interview") }}</v-alert
        >
      </v-col>
    </v-row>

    <v-row class="mb-10">
      <div>
        <v-btn icon color="primary" @click="$router.back()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
    </v-row>

    <v-row
      class="d-flex flex-row justify-space-between align-center my-10 mx-1"
    >
      <div>
        <v-select
          :items="selectInterview"
          :label="$t('approved_candidates')"
          item-text="text"
          item-value="key"
          dense
          solo
          style="width: 250px"
          v-model="pageInterview"
          @change="selectInterviewPage()"
        ></v-select>
      </div>
      <div class="d-flex flex-row align-center">
        <div class="text-right px-0 ml-6">
          <form v-on:submit.prevent="search()">
            <v-text-field
              style="text-decoration: captalize"
              v-model="text"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              dense
              outlined
              @click:append="search()"
              @input="search()"
            ></v-text-field>
          </form>
        </div>
      </div>
    </v-row>

    <v-row>
      <!-- <pre>{{ approvedCandidates }}</pre> -->
      <v-col
        cols="12"
        md="3"
        v-for="candidate in approvedCandidates"
        :key="candidate.id"
      >
        <interview-approved-card
          :candidate="candidate"
          :interviewId="$attrs.id"
        />
      </v-col>
    </v-row>

    <send-proposal-candidate-dialog />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_INTERVIEW_BY_ID_QUERY } from "../graphql/Query.gql";
import InterviewApprovedCard from "../components/candidate/InterviewApprovedCard.vue";
import SendProposalCandidateDialog from "../components/SendProposalDialog.vue";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
export default {
  name: "ApprovedCandidate",

  components: {
    InterviewApprovedCard,
    SendProposalCandidateDialog,
  },
  mixins: [replaceSpecialCharsMixins],
  data: () => ({
    alert: true,
    text: "",
    interview: [],
    pageInterview:""
  }),

  apollo: {
    interview: {
      query: GET_INTERVIEW_BY_ID_QUERY,
      variables() {
        return { id: this.$attrs.id };
      },
      fetchPolicy: "no-cache",
    },
  },

  computed: {
    ...mapGetters({
      getBlockedCandidates: "candidate/getBlockedCandidates",
    }),
    selectInterview() {
      return [
        {
          key: "concluded_canceled_interviews",
          text: this.$t("concluded_canceled_interviews"),
        },
        { key: "scheduled_interviews", text: this.$t("scheduled_interviews") },
      ];
    },
    approvedCandidates() {
      const blockeds = this.getBlockedCandidates;
      const candidates = this.interview.candidates;
      let resultItems = candidates
        ? candidates.map((findCandidate) => {
            const findIndex = blockeds.findIndex(
              (blockedId) =>
                findCandidate.interviewee &&
                blockedId == findCandidate.interviewee.id
            );
            const stage = findCandidate.stages.find(
              (findStage) => findStage.name === findCandidate.currentStage
            );
            return {
              ...findCandidate,
              address: this.interview.address,
              subject: this.interview.subject,
              telephone: this.interview.telephone,
              entity: this.interview.entity.name,
              timestemp: `${stage.date} ${stage.time} ${stage.groupBy}`,
              blocked: findIndex > -1,
            };
          })
        : [];

      resultItems = resultItems.filter(
        (filterCandidate) => filterCandidate.status === "approved"
      );

      if (this.text) {
        const text = this.replaceSpecialChars(this.text);
        resultItems = resultItems.filter((c) =>
          this.replaceSpecialChars(c.interviewee.fullName).includes(text)
        );
      }

      return resultItems;
    },
  },
  methods:{
    selectInterviewPage() {
      console.log(this.pageInterview);
      if (this.pageInterview === "concluded_canceled_interviews") {
        this.$router.push({
          name: "completed.interviews",
          params: {
            id: this.$route.params.id,
          },
        });
      }
      if (this.pageInterview === "scheduled_interviews") {
        this.$router.push({
          name: "scheduled.interviews",
          params: {
            id: this.$route.params.id,
          },
        });
      }
    },
  }
};
</script>